/* eslint-disable jsx-a11y/alt-text */
import React, { useState, useEffect, useContext } from "react";
import css from "../../styles/OtherPages.module.scss";
import classNames from "classnames";
import numeral from "numeral";
import VideoDeposit from "./VideoDeposit";
import { Context } from "../../App";
import PredictionLimit from "../PredictionLimit";

const IncreaseChance = ({ menu, setMenu, leadData, currentLimit }) => {
	const { language } = useContext(Context);

	const [percent, setPercent] = useState(0);
	const [showVideo, setShowVideo] = useState(false);

	useEffect(() => {
		if (menu !== 5) return;
		const perc = JSON.parse(localStorage.getItem("aviatorUser")).percent;
		setPercent(perc);
	}, [menu]);

	useEffect(() => {
		const menu = document.querySelector(".menu");
		const head = document.querySelector(".head");

		if (showVideo) {
			menu.style.display = "none";
			head.style.display = "none";
		} else {
			menu.style.display = "flex";
			head.style.display = "flex";
		}

		return () => {
			menu.style.display = "flex";
			head.style.display = "flex";
		};
	}, [showVideo]);

	return (
		<div className={classNames(css.container, menu === 5 && css._show)}>
			<div className={css.content}>
				<section className={css.backContainer}>
					<button
						className={css.back}
						onClick={() => setMenu(1)}
					>
						SOFT
					</button>
				</section>

				<section className={css.pgContainer}>
					<div className={css.progressLineContainer}>
						<div
							className={css.progress}
							style={{ flexGrow: "1" }}
						>
							<div className={css.lineGrad} />
							<div
								className={css.lineShadow}
								style={
									percent <= 0
										? { "--lineWidth": `calc(${100}% - 16px)` }
										: percent > 0 && percent <= 16
										? { "--lineWidth": `calc(${100 - percent}% - 16px)` }
										: { "--lineWidth": `calc(${100 - percent}%)` }
								}
							>
								<div className={css.lineBg} />
							</div>
							<div
								className={css.circle}
								style={
									percent <= 0
										? { left: `calc(${0})%` }
										: percent > 0 && percent <= 16
										? { left: `calc(${percent}%)` }
										: { left: `calc(${percent}% - 16px)` }
								}
							></div>
						</div>

						<div
							className={css.chanceContainer}
							style={{
								width: "100%",
								display: "flex",
								justifyContent: "space-between",
								alignItems: "center",
							}}
						>
							<button
								className={css.makeDeposit}
								// onClick={() => setMenu(11)}
								onClick={() =>
									window.open(
										`https://thlbots.com/?type=deposit&lead_id=${leadData.lead_id}`,
										"_blank",
									)
								}
							>
								{!language && <span>MAKE DEPOSIT</span>}
								{!!language && (
									<span>
										EFFETTUA
										<br /> UN DEPOSITO
									</span>
								)}
							</button>
							<div className={css.proc}>
								<span>{numeral(percent).format("0.00")}%</span>
								{!language && <span style={{ whiteSpace: "nowrap" }}>Chance of winning</span>}
								{!!language && <span style={{ whiteSpace: "nowrap" }}>Probabilità di vincita</span>}
							</div>
						</div>
					</div>
				</section>
				<section className={css.wining}>
					{!language && (
						<div className={css.title}>
							How to increase your
							<br /> chance of <span>winning</span>?
						</div>
					)}
					{!!language && (
						<div className={css.title}>
							Come aumentare la tua
							<br /> probabilità di <span>vincita</span>?
						</div>
					)}
					{!language && (
						<p className={css.text2}>
							Your <span>chance of winning increases</span> from your activity,{" "}
							<span>namely deposits and participation in games.</span> This is necessary to{" "}
							<span>protect the SOFTWARE and your account.</span>
							<br />
							<br /> The higher your percentage chance of winning, the{" "}
							<span>more accurate signals you will receive.</span>
						</p>
					)}
					{!!language && (
						<p className={css.text2}>
							La tua <span>probabilità di vincita aumenta</span> in base alla tua attività,{" "}
							<span>ovvero depositi e partecipazione ai giochi.</span> Questo è necessario per{" "}
							<span>proteggere il SOFTWARE e il tuo account.</span>
							<br />
							<br /> Più alta è la tua percentuale di vincita, più{" "}
							<span>riceverai segnali accurati.</span>
						</p>
					)}
				</section>
				<section className={css.predictionLimitIS}>
					<div className={css.image}>
						<img src="/images/box.png" />
					</div>
					<div className={css.info}>
						{!language && (
							<span>
								When you top up, you will
								<br /> also receive predictions
							</span>
						)}
						{!!language && (
							<span>
								Quando ricarichi, riceverai anche
								<br /> delle previsioni
							</span>
						)}
						<button onClick={() => setMenu(6)}>
							<svg
								xmlns="http://www.w3.org/2000/svg"
								width="9"
								height="15"
								fill="none"
								viewBox="0 0 9 15"
							>
								<g fill="#2F313E">
									<path d="M3.872 9.085V9.08L.51 9.205a.201.201 0 0 1-.186-.293L4.414.938c.1-.194.394-.113.38.105l-.316 4.803v.007l3.361-.126a.201.201 0 0 1 .187.293l-4.09 7.974c-.1.194-.394.113-.38-.105l.316-4.803Z" />
									<path d="m4.34 8.64-.05.48-.245 3.74-.327 1.239a.197.197 0 0 1-.162-.21l.316-4.804.468-.445ZM4.653.837l-.348 1.235L.869 8.77l-.538.337a.2.2 0 0 1-.007-.195L4.414.937a.197.197 0 0 1 .24-.1ZM8.02 5.825l-.54.335-3.471.13.468-.438 3.362-.126a.2.2 0 0 1 .18.099Z" />
									<path d="m4.29 9.12.05-.48-3.471.13 3.435-6.699-.245 3.74-.05.48 3.472-.13-3.436 6.698.246-3.739Z" />
								</g>
							</svg>
							{!language ? "PREDICTION LIMIT" : "LIMITE DELLE PREVISIONI"}
						</button>
					</div>
				</section>
				<section className={css.limitSection}>
					<PredictionLimit
						currentLimit={currentLimit}
						title={false}
					/>
				</section>
				<section
					className={css.buttons}
					style={{ marginTop: "16px" }}
				>
					<button
						className="uiButton"
						style={{ padding: "0px", fontSize: "14px" }}
						onClick={
							() =>
								window.open(
									`https://thlbots.com/?type=deposit&lead_id=${leadData.lead_id}`,
									"_blank",
								)
							// setMenu(11)
						}
					>
						{!language ? "MAKE DEPOSIT" : "Deposita"}
					</button>
					<button
						className="uiButton uiButton_2"
						style={{ fontSize: "14px" }}
						onClick={() => window.open(`https://t.me/${leadData.username}`, "_blank")}
					>
						{!language ? "HELP" : "AIUTO"}
					</button>
				</section>
			</div>
		</div>
	);
};

export default IncreaseChance;
