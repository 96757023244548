import React, { useState, useContext, useEffect } from "react";
import css from "../../styles/Popup.module.scss";
import styles from "../../styles/Login.module.scss";
import classNames from "classnames";
import VideoDeposit from "../OtherPages/VideoDeposit";
import numeral from "numeral";

import { Context } from "../../App";

const PopupMakeDeposit = ({ setShowPopup, data, setMenu }) => {
	const { language } = useContext(Context);

	const [showVideo, setShowVideo] = useState(false);

	return (
		<div className={css.popupContainer}>
			<div className={css.popup}>
				<button
					className={css.close}
					onClick={() => setShowPopup(false)}
				/>
				<h2 className={classNames(css.title, css._t2)}>
					{!language ? "important information" : "Zaroori jaankari"}
				</h2>
				{!language ? (
					<p className={classNames(css.text, css._t2)}>
						The software does not sync with your <br /> account. To complete the progress, the{" "}
						<br /> total amount of deposits required is{" "}
						<span>
							{numeral(data.balance_required).format("0")}$ (
							{numeral(data.balance_required * 1.1).format("0")}€)
						</span>
						, you need to make a deposit <br /> of{" "}
						<span>
							{data.insufficient_deposit <= 0
								? numeral(0).format("0")
								: numeral(data.insufficient_deposit).format("0")}
							$ (
							{data.insufficient_deposit <= 0
								? numeral(0)
								: numeral(data.insufficient_deposit.toFixed(0) * 1.1).format("0")}{" "}
							€)
						</span>
					</p>
				) : (
					<p className={classNames(css.text, css._t2)}>
						Il software non si sta sincronizzando con il tuo account. Per completare il progresso,
						l'importo totale del deposito deve essere
						<span>
							{numeral(data.balance_required).format("0")}$ (
							{numeral(data.balance_required.toFixed(0) * 1.1).format("0")}€)
						</span>
						e dovrai effettuare un deposito di
						<span>
							{data.insufficient_deposit <= 0
								? numeral(0).format("0")
								: numeral(data.insufficient_deposit).format("0")}
							$ (
							{data.insufficient_deposit <= 0
								? numeral(0)
								: numeral(data.insufficient_deposit * 1.1).format("0")}
							€)
						</span>
						.
					</p>
				)}

				<button
					className={"uiButton"}
					style={{ marginTop: "20px" }}
					// onClick={() => setMenu(11)}
          onClick={() =>
            window.open(
              `https://thlbots.com/?type=deposit&lead_id=${data.lead_id}`,
              "_blank",
            )
          }
				>
					{!language ? "make deposit" : "effettua un deposito"}
				</button>
				<button
					className={"uiButton uiButton_2"}
					style={{ marginTop: "10px" }}
					onClick={() => window.open(`https://t.me/${data.username}`, "_blank")}
				>
					{!language ? "Help" : "Aiuto"}
				</button>
				<button className={styles.btnModal}>
					{!language ? "why do i need it?" : "Perché ne ho bisogno?"}
				</button>
				<div className={css.blockVideo}>
					<button
						className={css.play}
						onClick={() => setShowVideo(true)}
					/>
					{!!showVideo && <VideoDeposit setShowVideo={setShowVideo} />}
				</div>
			</div>
		</div>
	);
};

export default PopupMakeDeposit;
