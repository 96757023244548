/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from "react";
import css from "../../styles/Login.module.scss";
import classNames from "classnames";
import Plane from "../Plane";
import axios from "axios";
import { PhoneNumberUtil } from "google-libphonenumber";
import { PhoneInput } from "react-international-phone";
import { toast } from "react-toastify";
import { getCountriesForRegion, getCountryCallingCode } from "libphonenumber-js";

const phoneUtil = PhoneNumberUtil.getInstance();

const isPhoneValid = phone => {
	try {
		return phoneUtil.isValidNumber(phoneUtil.parseAndKeepRawInput(phone));
	} catch (error) {
		return false;
	}
};

const Signup = ({ userData, setUserData, setPath }) => {
	const [lang, setLang] = useState(false);

	const [data, setData] = useState({});
	const [isValidEmail, setIsValidEmail] = useState(true);
	const [isValidPhone, setIsValidPhone] = useState(true);

	const handleChange = e => {
		const { name, value } = e.target;
		const newData = { ...data };

		newData[name] = value;
		setData(newData);
		setIsValidEmail(true);
	};

	const handleSubmit = async () => {
		const isValid = isPhoneValid(data?.phone);
		setIsValidPhone(isValid);

		if (!isValid) {
			const timeout2 = setTimeout(() => {
				setIsValidPhone(true);
				return () => clearTimeout(timeout2);
			}, 300);
			return;
		}

		const emailPattern = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
		setIsValidEmail(emailPattern.test(data.email));

		if (!emailPattern.test(data.email)) {
			const timeout1 = setTimeout(() => {
				setIsValidEmail(true);
				return () => clearTimeout(timeout1);
			}, 300);
			return;
		}

		try {
			const newData = {
				phone: data.phone.replace(/[^0-9]+/g, ""),
				email: data.email,
				platform_id: userData.platform_id,
			};
			const response = await axios.post(
				`${process.env.REACT_APP_HOST}/api/AviatorSoft/Client/registration`,
				newData,
			);
			if (!!response?.data?.success) {
				setUserData(response?.data?.data);
				setPath("");
			} else {
				const errors = Object.values(response?.data?.data);
				errors.forEach((el, index) => {
					setTimeout(() => {
						errorMessage(el[0]);
					}, index * 500);
				});
				console.log(errors);
			}
		} catch (error) {
			console.error(error);
		}
	};

	useEffect(() => {
		if (!isValidPhone) {
			errorMessage("Incorrect phone number format");
		}
		if (!isValidEmail) {
			errorMessage("Email format is not valid");
		}
	}, [isValidEmail, isValidPhone]);

	const errorMessage = message => {
		toast.error(message, {
			position: "top-right",
			autoClose: 3000,
			hideProgressBar: false,
			closeOnClick: true,
			pauseOnHover: true,
			draggable: true,
			progress: undefined,
			theme: "dark",
		});
	};

	const message = message => {
		toast(message, {
			position: "top-right",
			autoClose: 3000,
			hideProgressBar: false,
			closeOnClick: true,
			pauseOnHover: true,
			draggable: true,
			progress: undefined,
			theme: "dark",
		});
	};

	const getIp = async () => {
		try {
			const response = await fetch("https://test.traffhunt.com/api/getUserIP");
			if (!response.ok) throw new Error("Network response was not ok");
			const resId = await response.json();
			return resId.data;
		} catch (error) {
			console.error("Failed to get IP:", error);
			return null;
		}
	};

	const getCountryCodeFromIP = async () => {
		try {
			const response = await getIp();
			const countryCode = response.country_code;

			const callingCode = getCountryCallingCode(countryCode);
			setData({ phone: `+${callingCode}` });
		} catch (error) {
			console.error("Помилка при отриманні інформації за IP:", error);
		}
	};

	useEffect(() => {
		getCountryCodeFromIP();
	}, []);

	return (
		<div className="uiContainer2">
			<div className={css.main}>
				<div className={classNames(css.screen, css._firstScreen)}>
					<div className="uiBgElement" />
					<div className={classNames(css.signin, css._signup)}>
						<span className={css.title}>REGISTRATION</span>
						<div className={css.inputContainer}>
							<label
								className={classNames(css.inputLabel, css._phone)}
								htmlFor="input-phone"
							>
								<input
									className={classNames(css.input, !!data?.phone?.length && css.inputActive)}
									id="input-phone"
									type="tel"
									placeholder="PHONE"
									value={data?.phone || ""}
									name="phone"
									onChange={phone => {
										const newData = { ...data };
										newData.phone = phone.target.value;
										setData(newData);
									}}
								/>
							</label>
							<div className={css.inputBorder} />
						</div>
						<div className={css.inputContainer}>
							<label
								className={classNames(css.inputLabel, css._email)}
								htmlFor="input-email"
							>
								<input
									className={classNames(css.input, !!data?.email?.length && css.inputActive)}
									id="input-email"
									type="text"
									placeholder="EMAIL"
									value={data?.email}
									name="email"
									onChange={handleChange}
								/>
							</label>
							<div className={css.inputBorder} />
						</div>
						<div className={css.actions}>
							<button
								className="uiButton"
								onClick={handleSubmit}
							>
								Confirm
							</button>
							<button className="uiButton uiButton_2">Help</button>
						</div>
					</div>
				</div>
			</div>
		</div>
	);
};

export default Signup;
