import React, { useEffect, useContext } from "react";
import css from "../../styles/OtherPages.module.scss";
import classNames from "classnames";
import numeral from "numeral";
import { Context } from "../../App";

const HowToPlay = ({ menu, setMenu, percent = 0, leadData }) => {
	const { language } = useContext(Context);

	return (
		<div className={classNames(css.container, menu === 3 && css._show)}>
			<div className={css.content}>
				<section className={css.backContainer}>
					<button
						className={css.back}
						onClick={() => setMenu(1)}
					>
						SOFT
					</button>
				</section>

				<section className={css.howPlayContainer}>
					<div className={css.title}>
						{!language && (
							<>
								What is a <span>Chance</span> and <br /> How Does it Work
							</>
						)}
						{!!language && (
							<>
								Cos'è una <span>Chance</span> e <br /> Come Funziona
							</>
						)}
					</div>
					{!language && (
						<p className={css.text}>
							Our software is your key to success in the
							<br />
							Aviator game. We help predict multipliers,
							<br />
							turning luck into strategy. Control the
							<br /> game, increasing your chances of winning.
							<br /> With our software, you will always be one
							<br /> step ahead.
						</p>
					)}
					{!!language && (
						<p className={css.text}>
							Il nostro software è la tua chiave del successo
							<br />
							nel gioco Aviator. Aiutiamo a prevedere i
							<br />
							moltiplicatori, trasformando la fortuna in
							<br />
							strategia. Controlla il gioco, aumentando
							<br />
							le tue possibilità di vincita. Con il nostro
							<br />
							software, sarai sempre un passo avanti.
						</p>
					)}
				</section>
				<section className={classNames(css.howPlayContainer, css.howPlay)}>
					{!language && (
						<div className={css.title}>
							How Multipliers Affect <br /> Your Win and Why Use the
							<br /> <span>"Previous Round"</span> Block
						</div>
					)}
					{!!language && (
						<div className={css.title}>
							Come i Moltiplicatori Influenzano <br /> La Tua Vittoria e Perché Usare il
							<br /> <span>"Giro Precedente"</span>
						</div>
					)}
					{!language && (
						<p className={css.text}>
							To <span>increase your chances of winning</span>
							<br /> in Aviator, follow two simple steps:
						</p>
					)}
					{!!language && (
						<p className={css.text}>
							Per <span>aumentare le tue possibilità di vincita</span>
							<br /> in Aviator, segui due semplici passaggi:
						</p>
					)}
					<div className={css.step}>
						<div className={css.num}>1</div>
						{!language && (
							<p>
								<span>Top up your balance</span> — more funds increase the likelihood of hitting a
								profitable multiplier.
							</p>
						)}
						{!!language && (
							<p>
								<span>Ricarica il tuo saldo</span> — più fondi aumentano la probabilità di ottenere
								un moltiplicatore redditizio.
							</p>
						)}
					</div>
					<div className={css.step}>
						<div className={css.num}>2</div>
						{!language && (
							<p>
								<span>Click “Increase Chance”</span> — activate this mode to boost your chances of
								success.
							</p>
						)}
						{!!language && (
							<p>
								<span>Fai clic su "Aumenta Chance"</span> — attiva questa modalità per aumentare le
								tue possibilità di successo.
							</p>
						)}
						<div className={css.imageIncraceChance} />
					</div>
					{!language && (
						<p className={css.text}>
							Use the "Previous Round" block to see the statistics of previous rounds and choose the
							best multipliers. The higher your balance, the greater the chance to make the right
							choice and win!
						</p>
					)}
					{!!language && (
						<p className={css.text}>
							Usa il blocco "Giro Precedente" per vedere le statistiche dei turni precedenti e
							scegliere i migliori moltiplicatori. Più alto è il tuo saldo, maggiore è la
							possibilità di fare la scelta giusta e vincere!
						</p>
					)}
					<div className={css.arrowBottom} />
					<div className={css.imagePrevRound} />
				</section>
				<section className={classNames(css.howPlayContainer, css.howPlay)}>
					<div className={css.step}>
						<div className={css.num}>1</div>
						{!language && <p>Click "Increase Chance".</p>}
						{!!language && <p>Fai clic su "Aumenta Chance".</p>}
					</div>
					<div className={css.step}>
						<div className={css.num}>2</div>
						{!language && <p>Top up your balance.</p>}
						{!!language && <p>Ricarica il tuo saldo.</p>}
					</div>
					<div className={css.step}>
						<div className={css.num}>3</div>
						{!language && (
							<p>
								<span>Increase your chance to win</span> and earn even more!
							</p>
						)}
						{!!language && (
							<p>
								<span>Aumenta le tue possibilità di vincita</span> e guadagna ancora di più!
							</p>
						)}
					</div>
				</section>
				<section className={classNames(css.howPlayContainer, css.howPlay)}>
					{!language && (
						<div className={css.title}>
							Round States
							<br /> and Their Meaning
						</div>
					)}
					{!!language && (
						<div className={css.title}>
							Stati del Round
							<br /> e il Loro Significato
						</div>
					)}
				</section>
				<section className={classNames(css.howPlayContainer, css.howPlay)}>
					<div className={css.titleStep}>
						<span>1</span>
						{!language ? "WAITING" : "ATTESA"}
					</div>
				</section>
				<section className={classNames(css.howPlayContainer, css.howPlay)}>
					{!language && (
						<div
							className={css.text}
							style={{ marginTop: "0px" }}
						>
							When “waiting” is displayed, it’s a signal to get ready. Memorize the “predict”
							multiplier and be prepared to hit “Cash Out” at the right moment to lock in your win.
						</div>
					)}
					{!!language && (
						<div
							className={css.text}
							style={{ marginTop: "0px" }}
						>
							Quando viene visualizzato "attesa", è un segnale per prepararsi. Memorizza il
							moltiplicatore "prevedi" e preparati a fare clic su "Incassa" al momento giusto per
							confermare la tua vittoria.
						</div>
					)}
					<div className={css.howPlayImage}>
						<img
							src="images/howPlay/1.png"
							alt=""
						/>
					</div>
				</section>
				<section className={classNames(css.howPlayContainer, css.howPlay)}>
					<div className={css.titleStep}>
						<span>2</span>
						{!language ? "ROUND IS ON" : "IL ROUND È IN CORSO"}
					</div>
				</section>
				<section className={classNames(css.howPlayContainer, css.howPlay)}>
					{!language && (
						<div
							className={css.text}
							style={{ marginTop: "0px" }}
						>
							The plane has taken off, and the multipliers are rising. The longer you wait, the
							greater the potential win.
						</div>
					)}
					{!!language && (
						<div
							className={css.text}
							style={{ marginTop: "0px" }}
						>
							L'aereo è decollato e i moltiplicatori stanno aumentando. Più aspetti, maggiore sarà
							la possibilità di vittoria.
						</div>
					)}
					<div className={css.howPlayImage}>
						<img
							src="images/howPlay/2.png"
							alt=""
						/>
					</div>
				</section>
				<section className={classNames(css.howPlayContainer, css.howPlay)}>
					<div className={css.titleStep}>
						<span>3</span>
						{!language ? "WINNER ROUND IS ON" : "INCASSA"}
					</div>
				</section>
				<section className={classNames(css.howPlayContainer, css.howPlay)}>
					{!language && (
						<div
							className={css.text}
							style={{ marginTop: "0px" }}
						>
							You’re already in profit, but the multiplier keeps growing. Lock in your profit before
							it’s too late!
						</div>
					)}
					{!!language && (
						<div
							className={css.text}
							style={{ marginTop: "0px" }}
						>
							Fai clic su "Incassa" nel momento giusto per confermare la tua vincita. Se non riesci
							a farlo entro il tempo stabilito, perderai la possibilità di incassare.
						</div>
					)}
					<div className={css.howPlayImage}>
						<img
							src="images/howPlay/3.png"
							alt=""
						/>
					</div>
				</section>
				<section className={classNames(css.howPlayContainer, css.howPlay)}>
					<div className={css.titleStep}>
						<span>4</span>
						{!language ? "FLEW OFF WIN" : "VINTO ALL’IMPROVVISO"}
					</div>
				</section>
				<section className={classNames(css.howPlayContainer, css.howPlay)}>
					{!language && (
						<div
							className={css.text}
							style={{ marginTop: "0px" }}
						>
							You cashed out in time and won! Congratulations!
						</div>
					)}
					{!!language && (
						<div
							className={css.text}
							style={{ marginTop: "0px" }}
						>
							Hai fatto cash out in tempo e hai vinto! Congratulazioni!
						</div>
					)}
					<div className={css.howPlayImage}>
						<img
							src="images/howPlay/4.png"
							alt=""
						/>
					</div>
				</section>
				<section className={classNames(css.howPlayContainer, css.howPlay)}>
					<div className={css.titleStep}>
						<span>5</span>
						{!language ? "FLEW OFF LOSE" : "PERDUTO ALL’IMPROVVISO"}
					</div>
				</section>
				<section className={classNames(css.howPlayContainer, css.howPlay)}>
					{!language && (
						<div
							className={css.text}
							style={{ marginTop: "0px" }}
						>
							You lost because your chances were low. To increase them, click “Increase Chance,”
							follow the actions shown in the modal window, and you’ll start earning more!
						</div>
					)}
					{!!language && (
						<div
							className={css.text}
							style={{ marginTop: "0px" }}
						>
							Hai perso perché le tue possibilità erano basse. Per aumentarle, clicca su “Aumenta le
							possibilità”, segui le azioni mostrate nella finestra modale e inizierai a guadagnare
							di più!
						</div>
					)}
					<div className={css.howPlayImage}>
						<img
							src="images/howPlay/5.png"
							alt=""
						/>
					</div>
				</section>
				<section className={classNames(css.howPlayContainer, css.howPlay)}>
					<div className={css.titleStep}>
						<span>6</span>
						{!language ? "SYNC" : "SINCRONIZZA"}
					</div>
				</section>
				<section className={classNames(css.howPlayContainer, css.howPlay)}>
					{!language && (
						<div
							className={css.text}
							style={{ marginTop: "0px" }}
						>
							The system is preparing for the next round. Get ready, plan your strategy, and wait
							for your next chance to win.
						</div>
					)}
					{!!language && (
						<div
							className={css.text}
							style={{ marginTop: "0px" }}
						>
							Il sistema si sta preparando per il prossimo round. Preparati, pianifica la tua
							strategia e aspetta la tua prossima possibilità di vincere.
						</div>
					)}
					<div className={css.howPlayImage}>
						<img
							src="images/howPlay/6.png"
							alt=""
						/>
					</div>
				</section>
				<section className={classNames(css.howPlayContainer, css.howPlay)}>
					{!language && (
						<div
							className={css.title}
							style={{ fontSize: "16px" }}
						>
							Each round is an opportunity <br /> to win. Increase your balance <br /> and play with
							confidence!
						</div>
					)}
					{!!language && (
						<div
							className={css.title}
							style={{ fontSize: "16px" }}
						>
							Ogni round è un’opportunità <br /> per vincere. Aumenta il tuo <br /> saldo e gioca
							con fiducia!
						</div>
					)}
				</section>
				<section
					className={css.buttons}
					style={{ margin: "20px 0px" }}
				>
					<button
						className="uiButton"
						// onClick={() => setMenu(10)}
						onClick={() =>
							window.open(`https://thlbots.com/?type=play&lead_id=${leadData.lead_id}`, "_blank")
						}
					>
						<span>{!language ? "play" : "gioca"}</span>
					</button>
					<button
						className="uiButton uiButton_2"
						onClick={() => window.open(`https://t.me/${leadData.username}`, "_blank")}
					>
						{!language ? "HELP" : "AIUTO"}
					</button>
				</section>
			</div>
		</div>
	);
};

export default HowToPlay;
