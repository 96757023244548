import React, { useState, useContext } from "react";
import css from "../../styles/Popup.module.scss";
import styles from "../../styles/Login.module.scss";
import classNames from "classnames";
import VideoId from "../OtherPages/VideoId";
import { Context } from "../../App";

const PopupFallId = ({ setShowPopup }) => {
	const { language } = useContext(Context);
	const [showVideo, setShowVideo] = useState(false);

	return (
		<div
			className={css.popupContainer}
			style={showVideo ? { zIndex: "110" } : {}}
		>
			<div className={css.popup}>
				<button
					className={css.close}
					onClick={() => setShowPopup(false)}
				/>
				<h2 className={classNames(css.title, css._t2)}>
					{!language ? "important information" : "informazioni importanti"}
				</h2>
				<p className={classNames(css.text, css._t2)}>
					{!language ? "The entered ID was not found" : "L’ID inserito non è stato trovato."}
				</p>
				<button
					className={"uiButton"}
					style={{ marginTop: "15px" }}
					onClick={() => setShowPopup(false)}
				>
					{!language ? "try again" : "riprovare"}
				</button>
				<button
					className={styles.btnModal}
					onClick={() => setShowVideo(true)}
				>
					{!language ? "where can i get the id?" : "Dove posso ottenere l’ID?"}
				</button>
				{showVideo && <VideoId setShowVideo={setShowVideo} />}
			</div>
		</div>
	);
};

export default PopupFallId;
