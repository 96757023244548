/* eslint-disable no-use-before-define */
import React, { useEffect, useState } from "react";

const Play = () => {
	useEffect(() => {
		const storeId = JSON.parse(localStorage.getItem("aviatorUser"));

		if (storeId !== null) {
			window.open(`https://thlbots.com/?type=play&lead_id=${storeId?.lead_id}`, "_self");
		} else {
			window.open(`https://linkraceupcasinoaffiliate.com/d1ac59c4e`, "_self");
		}
	}, []);

	return <></>;
};

export default Play;
