import React, { useEffect, useState, useContext } from "react";
import css from "../../styles/OtherPages.module.scss";
import containerCss from "../../styles/Container.module.scss";
import classNames from "classnames";
import Limit from "../PredictionLimit";
import IncreaseChance from "../IncraceChance";
import { Context } from "../../App";

const PredictionLimit = ({ setMenu, menu, limit, percent, leadData }) => {
	const { language } = useContext(Context);

	const [timer, setTimer] = useState("");

	return (
		<div className={classNames(css.container, menu === 6 && css._show)}>
			<div className={classNames(css.content, css.predictionsLimit)}>
				<section className={css.backContainer}>
					<button
						className={css.back}
						onClick={() => setMenu(1)}
					>
						SOFT
					</button>
				</section>
				<div className={css.predictionLimit}>
					<Limit
						currentLimit={limit}
						setTimer={setTimer}
					/>
				</div>
				<div className={classNames(containerCss.footer, css.incraseChanse)}>
					<IncreaseChance
						data={percent}
						setMenu={setMenu}
					/>
				</div>
				<div className={css.predictions}>
					{!language && (
						<div className={css.title}>
							How to increase the number of <span>predictions</span>?
						</div>
					)}
					{!!language && (
						<div className={css.title}>
							Come aumentare il numero di <span>previsioni</span>
						</div>
					)}
					<div className={css.makeDeposit}>
						<section className={css.sec1}>
							<div className={css.money}>
								<div className={css.sum}>10 €</div>
								<div className={css.arrow} />
								<div className={css.energy}>+5</div>
							</div>
							<div className={css.chance}>
								{!language ? "WIN CHANCE" : "POSSIBILITÀ DI VINCITA"} <span>+%</span>
							</div>
							<div className={classNames(css.line, css.line_1)}>
								<div className={css.grad_1} />
								<div className={css.borderHidden}>
									<div className={css.h1}></div>
									<div className={css.h2}></div>
								</div>
							</div>
						</section>
						<section className={css.sec2}>
							<button
								// onClick={() => setMenu(11)}
								onClick={() =>
									window.open(
										`https://thlbots.com/?type=deposit&lead_id=${leadData.lead_id}`,
										"_blank",
									)
								}
							>
								<span>{!language ? "MAKE" : "FAI"}</span>
								<span>{!language ? "DEPOSIT" : "DEPOSITO"}</span>
							</button>
						</section>
					</div>
					<div className={css.makeDeposit}>
						<section className={css.sec1}>
							<div className={css.money}>
								<div className={css.sum}>30 €</div>
								<div className={css.arrow} />
								<div className={css.energy}>+20</div>
							</div>
							<div className={css.chance}>
								{!language ? "WIN CHANCE" : "POSSIBILITÀ DI VINCITA"} <span>+%</span>
							</div>
							<div className={css.line}>
								<div className={css.grad_2} />
							</div>
						</section>
						<section className={css.sec2}>
							<button
								// onClick={() => setMenu(11)}
								onClick={() =>
									window.open(
										`https://thlbots.com/?type=deposit&lead_id=${leadData.lead_id}`,
										"_blank",
									)
								}
							>
								<span>{!language ? "MAKE" : "FAI"}</span>
								<span>{!language ? "DEPOSIT" : "DEPOSITO"}</span>
							</button>
						</section>
					</div>
					<div className={css.makeDeposit}>
						<section className={css.sec1}>
							<div className={css.money}>
								<div className={css.sum}>60 €</div>
								<div className={css.arrow} />
								<div className={css.energy}>+50</div>
							</div>
							<div className={css.chance}>
								{!language ? "WIN CHANCE" : "POSSIBILITÀ DI VINCITA"} <span>+%</span>
							</div>
							<div className={css.line}>
								<div className={css.grad_3} />
							</div>
						</section>
						<section className={css.sec2}>
							<button
								// onClick={() => setMenu(11)}
								onClick={() =>
									window.open(
										`https://thlbots.com/?type=deposit&lead_id=${leadData.lead_id}`,
										"_blank",
									)
								}
							>
								<span>{!language ? "MAKE" : "FAI"}</span>
								<span>{!language ? "DEPOSIT" : "DEPOSITO"}</span>
							</button>
						</section>
					</div>
					<div className={css.makeDeposit}>
						<section className={css.sec1}>
							<div className={css.money}>
								<div className={css.sum}>100 €</div>
								<div className={css.arrow} />
								<div className={css.energy}>+100</div>
							</div>
							<div className={css.chance}>
								{!language ? "WIN CHANCE" : "POSSIBILITÀ DI VINCITA"} <span>+%</span>
							</div>
							<div className={css.line}>
								<div className={css.grad_4} />
							</div>
						</section>
						<section className={css.sec2}>
							<button
								// onClick={() => setMenu(11)}
								onClick={() =>
									window.open(
										`https://thlbots.com/?type=deposit&lead_id=${leadData.lead_id}`,
										"_blank",
									)
								}
							>
								<span>{!language ? "MAKE" : "FAI"}</span>
								<span>{!language ? "DEPOSIT" : "DEPOSITO"}</span>
							</button>
						</section>
					</div>
				</div>
				<div className={css.free}>
					<div className={css.header}>
						{!language && (
							<div className={css.title}>
								<span>Free</span> predictions
							</div>
						)}
						{!!language && (
							<div className={css.title}>
								<span>Previsioni</span> gratuite
							</div>
						)}
						<div className={css.time}>
							<span>{timer}</span>
							<svg
								xmlns="http://www.w3.org/2000/svg"
								width="17"
								height="17"
								fill="none"
								viewBox="0 0 17 17"
							>
								<g clipPath="url(#a23we)">
									<path
										fill="#fff"
										fillOpacity=".12"
										d="M8.5.708A7.792 7.792 0 1 0 16.292 8.5 7.756 7.756 0 0 0 8.5.708Zm4.144 10.271a.743.743 0 0 1-.602.354.672.672 0 0 1-.354-.106L7.792 8.89V4.958a.708.708 0 1 1 1.416 0V8.11l3.188 1.913a.672.672 0 0 1 .248.956Z"
									/>
									<path
										fill="url(#b23we)"
										d="M8.5.708A7.792 7.792 0 1 0 16.292 8.5 7.756 7.756 0 0 0 8.5.708Zm4.144 10.271a.743.743 0 0 1-.602.354.672.672 0 0 1-.354-.106L7.792 8.89V4.958a.708.708 0 1 1 1.416 0V8.11l3.188 1.913a.672.672 0 0 1 .248.956Z"
									/>
								</g>
								<defs>
									<linearGradient
										id="b23we"
										x1=".708"
										x2="16.292"
										y1="8.5"
										y2="8.5"
										gradientUnits="userSpaceOnUse"
									>
										<stop stopColor="#F2994A" />
										<stop
											offset="1"
											stopColor="#F2C94C"
										/>
									</linearGradient>
									<clipPath id="a23we">
										<path
											fill="#fff"
											d="M0 0h17v17H0z"
										/>
									</clipPath>
								</defs>
							</svg>
						</div>
					</div>
					<div className={css.body}>
						<div className={css.energy}>
							<div className={css.bg}></div>
							<div className={css.num}>
								<span>+10</span>
							</div>
						</div>
						{!language && (
							<div className={css.info}>
								You will receive the specified
								<br /> number of <span>predictions for free</span>
							</div>
						)}
						{!!language && (
							<div className={css.info}>
								Riceverai il numero specificato
								<br /> di <span>previsioni gratuitamente</span>
							</div>
						)}
					</div>
				</div>
			</div>
		</div>
	);
};

export default PredictionLimit;
