import React, { useContext } from "react";
import css from "../../styles/OtherPages.module.scss";
import classNames from "classnames";
import numeral from "numeral";
import { Context } from "../../App";

const Help = ({ menu, setMenu, percent = 0, leadData }) => {
	const { language } = useContext(Context);

	return (
		<div className={classNames(css.container, menu === 2 && css._show)}>
			<div className={css.content}>
				<section className={css.backContainer}>
					<button
						className={css.back}
						onClick={() => setMenu(1)}
					>
						SOFT
					</button>
				</section>
				<section className={css.help}>
					<div className={css.contentHelp}>
						{!language && (
							<div className={css.title}>
								If you have any <span>questions</span>
							</div>
						)}
						{!!language && (
							<div className={css.title}>
								Se hai <span>domande</span>
							</div>
            )}
						<button onClick={() => window.open(`https://t.me/${leadData.username}`, "_blank")}>
							{!language && <span>MESSAGE ME</span>}
							{!!language && <span>SCRIVIMI</span>}
						</button>
						{!language && <span className={css.info}>Contact the software creator</span>}
						{!!language && <span className={css.info}>Contatta il creatore del software</span>}
					</div>
				</section>
				<section className={css.help}>
					<div className={css.contentHelp}>
						{!language && <div className={css.title}>
							<span>Feedback</span> from others and training
						</div>}
						{!!language && <div className={css.title}>
							<span>Feedback</span> da parte degli altri e formazione
						</div>}
						<button>
							{!language && <span>SUBSCRIBE</span>}
							{!!language && <span>ISCRIVITI</span>}
						</button>
						{!language && <span className={css.info}>Telegram channel </span>}
						{!!language && <span className={css.info}>Canale Telegram </span>}
					</div>
				</section>
			</div>
		</div>
	);
};

export default Help;
